export class FetchService {
    private baseUrl: string;
  
    constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
    }
  
    // GET all items
    async getItems(endpoint: string): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
      }
    }
  
    // GET a single item by ID
    async getItemById(endpoint: string, id: string): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error(`Error fetching item with ID ${id}:`, error);
        throw error;
      }
    }
  
    // POST (Create) a new item
    async createItem(endpoint: string, item: any): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(item),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error('Error creating item:', error);
        throw error;
      }
    }
  
    // PUT (Replace) an existing item by ID
    async replaceItem(endpoint: string, id: string, item: any): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(item),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error(`Error replacing item with ID ${id}:`, error);
        throw error;
      }
    }
  
    // PATCH (Update) an existing item by ID
    async updateItem(endpoint: string, id: string, partialItem: any): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(partialItem),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error(`Error updating item with ID ${id}:`, error);
        throw error;
      }
    }
  
    // DELETE an item by ID
    async deleteItem(endpoint: string, id: string): Promise<void> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(`${this.baseUrl}/${endpoint}/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error(`Error deleting item with ID ${id}:`, error);
        throw error;
      }
    }

    // GET Label URL by SKU (for fetching label from Lambda)
    async getItem(endpoint: string): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error('Error fetching label:', error);
        throw error;
      }
    }
    
    async sellItem(endpoint: string, item: any): Promise<any> {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(item),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('selling item', item);
        return await response.json();
      } catch (error) {
        console.error('Error selling item:', error);
        throw error;
      }
    }
    

    
  }
  