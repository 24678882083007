import React from 'react';

interface dailyProduction {
    thickness: number;
    productType: string;
    turSayisi: number;
    lengthTotal: number;
}

interface productionData {
    machineNumber: number;
    series: number;
    puppetCount: number;
    headCount: number;
    
}


const dailyProduct: React.FC = () => {
  return (
    <div>
       <h1>Rapor Ekranı</h1>
    </div>
  );
};

export default dailyProduct;