import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home: React.FC = () => {
  return (
    <div className="container mt-5">
      {/* Dashboard Overview */}
      <h2 className="mb-4">Kontrol Paneli</h2>
      <div className="row">
        <div className="col-sm-4">
          <div className="card bg-info text-white mb-4">
            <div className="card-body">
              <h5 className="card-title">Tüm Ürünler</h5>
              <p className="card-text">500</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card bg-warning text-white mb-4">
            <div className="card-body">
              <h5 className="card-title">Stoktaki Ürünler</h5>
              <p className="card-text">350</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card bg-danger text-white mb-4">
            <div className="card-body">
              <h5 className="card-title">Satılan Ürünler</h5>
              <p className="card-text">150</p>
            </div>
          </div>
        </div>
      </div>

      {/* Action Cards */}
      <h2 className="mb-4">Eylemler</h2>
      <div className="row">
        <div className="col-sm-4">
          <div className="card text-center mb-4">
            <div className="card-body">
              <h5 className="card-title">Envantere Git</h5>
              <p className="card-text">Envanterinin güncel durumunu incele.</p>
              <a href="/inventory" className="btn btn-primary">Git</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card text-center mb-4">
            <div className="card-body">
              <h5 className="card-title">Rapor Hazırla</h5>
              <p className="card-text">Envanter verilerine dayalı raporlar hazırla.</p>
              <a href="/reports" className="btn btn-secondary">Hazırla</a>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card text-center mb-4">
            <div className="card-body">
              <h5 className="card-title">Satılanlara Git</h5>
              <p className="card-text">Envanterden çıkış yap.</p>
              <a href="/sold" className="btn btn-danger">Git</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
